@font-face {
    font-family: 'icomoon';
    src:  url('../icons/icomoon.eot?lgr36z');
    src:  url('../icons/icomoon.eot?lgr36z#iefix') format('embedded-opentype'),
      url('../icons/icomoon.ttf?lgr36z') format('truetype'),
      url('../icons/icomoon.woff?lgr36z') format('woff'),
      url('../icons/icomoon.svg?lgr36z#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }
  
  [class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    font-size: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  .icon-back_arrow:before {
    content: "\e900";
  }
  .icon-check1:before {
    content: "\e901";
  }
  .icon-cross:before {
    content: "\e902";
  }
  .icon-default_user:before {
    content: "\e903";
  }
  .icon-edit_pencile:before {
    content: "\e904";
  }
  .icon-facebook:before {
    content: "\e905";
  }
  .icon-footer_chat:before {
    content: "\e906";
  }
  .icon-footer_home:before {
    content: "\e907";
  }
  .icon-forward_arrow:before {
    content: "\e908";
  }
  .icon-google:before {
    content: "\e909";
  }
  .icon-instagram:before {
    content: "\e90a";
  }
  .icon-profile_chat:before {
    content: "\e90b";
  }
  .icon-profile_question:before {
    content: "\e90c";
  }
  .icon-profile_setting:before {
    content: "\e90d";
  }
  .icon-profile_share:before {
    content: "\e90e";
  }
  .icon-profile_user:before {
    content: "\e90f";
  }
  .icon-search:before {
    content: "\e910";
  }
  .icon-setting:before {
    content: "\e911";
  }
  .icon-thunder:before {
    content: "\e912";
  }
  .icon-twitter:before {
    content: "\e913";
  }
  